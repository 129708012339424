import { useEffect } from "react";
import "./app.css";
import "./custom.css";

export default () => {
    const data = [{ id: "dc931304-3745-4465-9246-3b86608ecf2e", name: "Zak's Kebab House", available: true }];

    const onOrder = (link) => {
        window.location.href = `https://order.tabin.co.nz/restaurant/${link}`;
    };

    return (
        <>
            <div className="restaurant-list">
                <img
                    src={`https://d1hfsnuz4i23pd.cloudfront.net/protected/ap-southeast-2:25450a52-7a40-45ea-a868-a9d98c6ff86a/2024-06-20_06:59:24.838-zaks-kebab-house.webp`}
                    className="restaurant-list-logo"
                    alt="logo"
                />
                <div className="h4 text-center mb-6">
                    At Zak's Kebab House, indulge in our exceptionally tasty kebabs, crafted from premium ingredients. Ready to savor the flavor?
                    Click "Order Here" to begin!
                </div>
                {data &&
                    data.map((restaurant, index) => (
                        <div key={restaurant.id}>
                            {index != 0 && <div className="separator-4"></div>}
                            <div className="restaurant-list-item">
                                <div className="restaurant-name">{restaurant.name}</div>
                                <button
                                    className={`button ${restaurant.available ? "" : "disabled"}`}
                                    onClick={() => {
                                        restaurant.available && onOrder(restaurant.id);
                                    }}
                                >
                                    {restaurant.available ? "Order Here" : "Unavailable"}
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
